<template>
  <Container>
    <Header>
      Profiles
      <template #right>
        <BadgeBtn @click="openProfile()">
          + Profile
        </BadgeBtn>
      </template>
    </Header>
    <ProfileTable />
  </Container>
</template>

<script>
import ProfileTable from "../components/tabels/ProfileTable.vue"

import comp from "../components/base"
import { mapActions } from "vuex"
export default {
  name: "Profiles",
  components: {
    ...comp,
    ProfileTable,
  },
  methods: {
    ...mapActions(["openProfile"]),
  },
}
</script>
