<template>
  <Table>
    <template v-slot:headRow>
      <TH>Edit</TH>
      <TH>Name</TH>
      <TH>Type</TH>
    </template>
    <template v-slot:body>
      <TR v-for="(item, index) in profiles" :key="index">
        <TD>
          <ButtonGroup>
            <Button @click="openProfile(item.id)">
              <BIconPencilSquare />
            </Button>
            <Button @click="openDeleteProfile(item.id)">
              <BIconTrash />
            </Button>
          </ButtonGroup>
        </TD>
        <TD>{{ item.name }}</TD>
        <TD>{{ PROFILE.TEXT[item.type] }}</TD>
      </TR>
    </template>
  </Table>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { PROFILE } from "../../services/enums"
import comp from "../../components/base"
import icons from "../../assets/icons"

export default {
  name: "ProfileTable",
  components: {
    ...comp,
    ...icons,
  },
  setup() {
    return {
      PROFILE,
    }
  },
  methods: {
    ...mapActions(["openProfile"]),
    ...mapActions(["openDeleteProfile"]),
  },
  computed: {
    ...mapGetters(["profiles"]),
    // profiles() {
    //   return this.profiles
    // },
  },
}
</script>
